import { Divider, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import ContentFormTextGroup from '../../text/ContentFormTextGroup';
import ContentInputGroup from '../../../../input/ContentInputGroup';
import CourseBannerUpload from '../input/CourseBannerUpload';
import CourseInstructorsForm from './CourseInstructorsForm';
import CourseThumbnailUpload from '../input/CourseThumbnailUpload';
import TinyMceEditor from '../../../shared/TinyMceEditor';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function CourseDetailsForm() {
  const { t } = useTranslation();
  const { setCanAdvance, tabIndex, tabs, setTabs, setImagesContainerRef } = useCreateStore();
  const { watch } = useFormContext();
  const imagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setImagesContainerRef(imagesContainerRef);
  }, []);

  const { title, description, instructors } = watch();

  useEffect(() => {
    if (title !== '' && description !== '' && instructors.length > 0) {
      setCanAdvance(true);

      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        isDisabled: false,
      }));

      setTabs(updatedTabs);

      return;
    }

    setCanAdvance(false);

    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      isDisabled: true,
    }));

    setTabs(updatedTabs);
  }, [title, description, instructors]);

  return (
    <Stack>
      <ContentAuthoringBox>
        <Text variant="createHeading">{t('authoring.courses.courseDetails')}</Text>

        <ContentInputGroup
          label={t('authoring.courses.title')}
          placeholder=""
          formName="title"
          isRequired
        />

        <Divider marginY={8} borderColor="neutral.100" />
        <Stack spacing={6} marginY={8}>
          <ContentFormTextGroup
            label={t('authoring.courses.description.label')}
            helpText={t('authoring.courses.description.helpText')}
          />
          <TinyMceEditor
            isRequired
            formName="description"
            placeholder=""
            label=""
            useMenu={false}
            toolbarOpts="bold italic underline fontsize emoticons alignleft aligncenter alignright alignjustify outdent indent numlist bullist link removeformat undo redo"
          />
        </Stack>
      </ContentAuthoringBox>

      <ContentAuthoringBox>
        <Text ref={imagesContainerRef} variant="createHeading" marginBottom={8}>
          {t('authoring.courses.courseImages')}
        </Text>

        <Grid templateColumns="repeat(6, 1fr)" gap={8}>
          <GridItem colSpan={4}>
            <Stack height="full">
              <Text fontSize="16px" fontWeight={600} color="baseBlack">
                {t('authoring.courses.courseImages.bannerImage')}
              </Text>

              <CourseBannerUpload />
            </Stack>
          </GridItem>

          <GridItem colSpan={2}>
            <Stack height="full">
              <Text fontSize="16px" fontWeight={600} color="baseBlack">
                {t('authoring.courses.courseImages.thumbnailImage')}
              </Text>

              <CourseThumbnailUpload />
            </Stack>
          </GridItem>
        </Grid>
      </ContentAuthoringBox>

      <CourseInstructorsForm />
    </Stack>
  );
}
