/* eslint-disable react/jsx-props-no-spreading */

import {
  Content,
  ContentTypes,
  PartnerPermissionTypes,
  VideoExtras,
} from '@/client/services/api/graphql/gql/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import AuthoringLivePreview from '@/client/components/admin/create/content/AuthoringLivePreview';
import type { ContentFormValues } from '@/client/types/admin/content-authoring/ContentFormValues';
import CreateLayout from '@/client/components/admin/create/shared/layout/CreateLayout';
import EditVideoForm from '@/client/components/admin/create/content/forms/videos/EditVideoForm';
import TrackStateAlert from '@/client/components/admin/create/content/overlay/TrackStateAlert';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface VideoFormValues {
  lastTranscodeCheck: Date | null;
  videoDetails: { name: string; size: number } | null;
  video_type: string;
  videoUploadType: 'upload' | 'embed';
}

export type VideoFormData = Content & VideoExtras & ContentFormValues & VideoFormValues;

const videoDefaultValues: VideoFormData = {
  archived: false,
  creator: '',
  lastTranscodeCheck: null,
  videoDetails: null,
  video_type: '',
  videoUploadType: 'upload', // 'upload' | 'embed'
  id: '',
  company: '',
  title: '',
  duration: 0,
  private: false,
  privacyCollaborators: [],
  privacyLocations: [],
  privacyDepartments: [],
  privacyTeams: [],
  privacyOrgLevels: [],
  privacyGrades: [],
  privacyGroups: [],
  linkedCategory: [],
  contentType: ContentTypes.Video,
  link: '',
  description: '',
  exploreHide: true,
  draft: true,
  source: 'custom',
  sourceId: null,
  tags: [],
  approvalRequired: false,
  approved: false,
  transcoding: false,
  allowComments: false,
  partnerPermissions: PartnerPermissionTypes.Tenant,
  vttFiles: [],
  surveys: [],
  author: '',
  ratingConfiguration: {
    messaging: {
      individuals: [],
      slackChannels: [],
    },
    allowRating: false,
    allowFeedback: false,
    allowEdits: false,
    notifyFeedbackOnly: false,
    showUsersRating: false,
    totalValue: 5,
  },
  enable_collaborators: false,
  enable_surveys: false,
  enable_segmentation: false,
};

export default function EditVideo() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();
  useDocumentTitle(t('authoring.documentTitle.editVideo'));

  const allowance = {
    create_video: isAllowed(
      ['admin', 'content', 'all_create'],
      [['admin', 'content', 'videos_create']],
      ['admin', 'creator', 'collaborator'],
    ),
    edit_video: isAllowed(
      ['admin', 'content', 'all_edit'],
      [
        ['admin', 'content', 'videos_edit'],
        ['admin', 'content', 'my_content_edit'],
      ],
      ['admin', 'creator', 'collaborator'],
    ),
  };

  if ((id && !allowance.edit_video) || (!id && !allowance.create_video)) {
    return <Navigate to="/explore" replace />;
  }

  const methods = useForm<VideoFormData>({
    defaultValues: videoDefaultValues,
    mode: 'onBlur',
  });

  const { watch, formState, clearErrors } = methods;
  const title = watch('title');
  const author = watch('author');
  const description = watch('description');
  const { errors } = formState;

  useEffect(() => {
    if (errors.title && title !== '') clearErrors('title');

    if (errors.author && author !== '') clearErrors('author');

    if (errors.description && description !== '') clearErrors('description');
  }, [title, author, description]);

  return (
    <FormProvider {...methods}>
      <form>
        <CreateLayout
          previewElement={
            <AuthoringLivePreview
              contentType="video"
              titlePlaceholder={t('authoring.video.livePreview.titlePlaceholder')}
            />
          }
          formElement={<EditVideoForm />}
        />
      </form>

      <TrackStateAlert contentType="video" />
    </FormProvider>
  );
}
