import { Box, Icon, Stack, Text } from '@chakra-ui/react';
import {
  FileAssessment,
  TextAssessment,
  VideoAssessment,
} from '@/client/components/icons/ContinuIcons';
import { useEffect, useMemo, useRef } from 'react';

import { AssessmentFormData } from '@/client/routes/admin/create/content/assessments/EditAssessment';
import AssessmentGeneralDetailsForm from './AssessmentGeneralDetailsForm';
import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import ContentFormTextGroup from '../../text/ContentFormTextGroup';
import CreateRadioGroup from '../../../shared/input/CreateRadioGroup';
import FileResponseTypeForm from './file-response/FileResponseTypeForm';
import VideoResponseTypeForm from './video-response/VideoResponseTypeForm';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AssessmentDetailsForm() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();
  const { watch } = useFormContext<AssessmentFormData>();
  const { id } = useParams();
  const { tabs, setTabs, tabIndex, setCanAdvance } = useCreateStore();
  const videoResponseTypeRef = useRef<HTMLDivElement>(null);
  const fileResponseTypeRef = useRef<HTMLDivElement>(null);
  const generalDetailsRef = useRef<HTMLDivElement>(null);

  const allowVideoAssessments = company.video_assessments;

  const { assessmentType, questions, title, author, description } = watch();

  const videoResponseType =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'recording_type')?.value) ||
    undefined;

  const fileResponseType =
    (questions &&
      questions[0]?.questionOptions?.find((option) => option.name === 'upload_type')?.value) ||
    undefined;

  useEffect(() => {
    if (title === '' || author === '' || description === '') {
      setCanAdvance(false);

      const updatedTabs = tabs.map((tab, index) => {
        if (index === tabIndex) return tab;

        return {
          ...tab,
          isDisabled: true,
        };
      });

      setTabs(updatedTabs);

      return;
    }

    if (assessmentType === 'video' && !videoResponseType) {
      setCanAdvance(false);

      const updatedTabs = tabs.map((tab, index) => {
        if (index === tabIndex) return tab;

        return {
          ...tab,
          isDisabled: true,
        };
      });

      setTabs(updatedTabs);

      return;
    }

    if (assessmentType === 'file' && !fileResponseType) {
      setCanAdvance(false);

      const updatedTabs = tabs.map((tab, index) => {
        if (index === tabIndex) return tab;

        return {
          ...tab,
          isDisabled: true,
        };
      });

      setTabs(updatedTabs);

      return;
    }

    const updatedTabs = tabs.map((tab, index) => {
      if (index === tabIndex) return tab;

      return {
        ...tab,
        isDisabled: id ? false : index !== 1,
      };
    });

    setTabs(updatedTabs);

    setCanAdvance(true);
  }, [assessmentType, videoResponseType, fileResponseType, title, author, description]);

  useEffect(() => {
    if (assessmentType === 'video') {
      videoResponseTypeRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (assessmentType === 'file') {
      fileResponseTypeRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (assessmentType === 'text') {
      generalDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assessmentType]);

  const options = [
    {
      value: 'text',
      label: t('authoring.assessment.assessmentType.text'),
      icon: <Icon boxSize={12} as={TextAssessment} />,
    },
    {
      value: 'file',
      label: t('authoring.assessment.assessmentType.file'),
      icon: <Icon boxSize={12} as={FileAssessment} />,
    },
  ];

  const optionsWithVideo = [
    ...options,
    {
      value: 'video',
      label: t('authoring.assessment.assessmentType.video'),
      icon: <Icon boxSize={12} as={VideoAssessment} />,
    },
  ];

  return (
    <Stack>
      <ContentAuthoringBox>
        <Text variant="createHeading">{t('authoring.assessment.assessmentDetails')}</Text>

        <Box paddingY={8}>
          <ContentFormTextGroup
            label={t('authoring.assessment.assessmentType.label')}
            helpText={t('authoring.assessment.assessmentType.helpText')}
          />

          {((id && assessmentType) || !id) && (
            <CreateRadioGroup
              name="assessmentType"
              defaultValue={assessmentType}
              label=""
              isDisabled={!!id}
              options={!allowVideoAssessments ? options : optionsWithVideo}
            />
          )}
        </Box>

        {assessmentType === 'video' && (
          <Box ref={videoResponseTypeRef}>
            <VideoResponseTypeForm />
          </Box>
        )}

        {assessmentType === 'file' && (
          <Box ref={fileResponseTypeRef}>
            <FileResponseTypeForm />
          </Box>
        )}
      </ContentAuthoringBox>

      {((assessmentType === 'video' && videoResponseType) ||
        (assessmentType === 'file' && fileResponseType) ||
        assessmentType === 'text') && (
        <Box ref={generalDetailsRef}>
          <ContentAuthoringBox>
            <AssessmentGeneralDetailsForm />
          </ContentAuthoringBox>
        </Box>
      )}
    </Stack>
  );
}
